
import React from 'react';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import '../../../node_modules/slick-carousel/slick/slick.js';
import Slider from "react-slick";
import EventWhite from '../../Images/EventWhite.png';
import EventRead from '../../Images/EventRead.png';
import EventReadYellow from '../../Images/EventReadYellow.png';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "grey",borderRadius:"100px" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "grey",borderRadius:"100px" }}
        onClick={onClick}
      />
    );
  }
  
const CarouselSlider = () => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <Slider {...settings} >
                <div class="row g-4">
                    <div class="col-12">
                        <div class="card_bg">
                            <img src={EventWhite} class="card-img-top" alt="EventWhite" />
                            <div class="p-3 ">
                                <div className="row g-0 ">
                                    <div className="col-3">
                                        <h5 className="header_event_card_month">SEP</h5>
                                        <p className="text-muted header_event_card_date">16</p>
                                    </div>
                                    <div className="col-9">
                                        <h6 className="letest_event_card_title">Id anim sint occaecat amet laboris pariatur aute ipsum. </h6>
                                        <p className="text-muted letest_event_description_card">Id anim sint occaecat amet laboris pariatur aute ipsum.
                                            Laborum eu Lorem consequat sit
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-4">
                    <div class="col-12">
                        <div class="card_bg">
                            <img src={EventRead} class="card-img-top" alt="EventRead" />
                            <div class="p-3">
                                <div className="row g-0">
                                    <div className="col-3">
                                        <h5 className="header_event_card_month">SEP</h5>
                                        <p className="text-muted header_event_card_date">16</p>
                                    </div>
                                    <div className="col-9">
                                        <h6 className="letest_event_card_title">Id anim sint occaecat amet laboris pariatur aute ipsum. </h6>
                                        <p className="text-muted letest_event_description_card">Id anim sint occaecat amet laboris pariatur aute ipsum.
                                            Laborum eu Lorem consequat sit
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-4">
                    <div class="col-12">
                        <div class="card_bg">
                            <img src={EventReadYellow} class="card-img-top" alt="EventReadYellow" />
                            <div class="p-3">
                                <div className="row g-0">
                                    <div className="col-3">
                                        <h5 className="header_event_card_month">SEP</h5>
                                        <p className="text-muted header_event_card_date">16</p>
                                    </div>
                                    <div className="col-9">
                                        <h6 className="letest_event_card_title">Id anim sint occaecat amet laboris pariatur aute ipsum. </h6>
                                        <p className="text-muted letest_event_description_card">Id anim sint occaecat amet laboris pariatur aute ipsum.
                                            Laborum eu Lorem consequat sit
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-4">
                    <div class="col-12">
                        <div class="card_bg">
                            <img src={EventWhite} class="card-img-top" alt="EventWhite" />
                            <div class="p-3">
                                <div className="row g-0">
                                    <div className="col-3">
                                        <h5 className="header_event_card_month">SEP</h5>
                                        <p className="text-muted header_event_card_date">16</p>
                                    </div>
                                    <div className="col-9">
                                        <h6 className="letest_event_card_title">Id anim sint occaecat amet laboris pariatur aute ipsum. </h6>
                                        <p className="text-muted letest_event_description_card">Id anim sint occaecat amet laboris pariatur aute ipsum.
                                            Laborum eu Lorem consequat sit
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-4">
                    <div class="col-12">
                        <div class="card_bg">
                            <img src={EventWhite} class="card-img-top" alt="EventWhite" />
                            <div class="p-3">
                                <div className="row g-0">
                                    <div className="col-3">
                                        <h5 className="header_event_card_month">SEP</h5>
                                        <p className="text-muted header_event_card_date">16</p>
                                    </div>
                                    <div className="col-9">
                                        <h6 className="letest_event_card_title">Id anim sint occaecat amet laboris pariatur aute ipsum. </h6>
                                        <p className="text-muted letest_event_description_card">Id anim sint occaecat amet laboris pariatur aute ipsum.
                                            Laborum eu Lorem consequat sit
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-4">
                    <div class="col-12">
                        <div class="card_bg">
                            <img src={EventWhite} class="card-img-top" alt="EventWhite" />
                            <div class="p-3">
                                <div className="row g-0">
                                    <div className="col-3">
                                        <h5 className="header_event_card_month">SEP</h5>
                                        <p className="text-muted header_event_card_date">16</p>
                                    </div>
                                    <div className="col-9">
                                        <h6 className="letest_event_card_title">Id anim sint occaecat amet laboris pariatur aute ipsum. </h6>
                                        <p className="text-muted letest_event_description_card">Id anim sint occaecat amet laboris pariatur aute ipsum.
                                            Laborum eu Lorem consequat sit
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row g-4">
                    <div class="col-12">
                        <div class="card_bg">
                            <img src={EventWhite} class="card-img-top" alt="EventWhite" />
                            <div class="p-3">
                                <div className="row g-0">
                                    <div className="col-3">
                                        <h5 className="header_event_card_month">SEP</h5>
                                        <p className="text-muted header_event_card_date">16</p>
                                    </div>
                                    <div className="col-9">
                                        <h6 className="letest_event_card_title">Id anim sint occaecat amet laboris pariatur aute ipsum. </h6>
                                        <p className="text-muted letest_event_description_card">Id anim sint occaecat amet laboris pariatur aute ipsum.
                                            Laborum eu Lorem consequat sit
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Slider>
        </div>
    );
};

export default CarouselSlider;