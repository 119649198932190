import React from 'react';
import EventBanner from '../../Images/EventBanner.png';


const NewsEventBanner = () => {
    return (
        <>
            <div class="banner_event_news">
                <img src={EventBanner} className="img-fluid" alt="EventBanner" />
                <div class="content_img">
                    <div class="container d-flex justify-content-start">
                        <h1 class="text-white">News & Event</h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewsEventBanner;