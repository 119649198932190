import React from 'react';
import AdmissionBanner from './AdmissionBanner';

const OnlineAdmissionProcess = () => {
    return (
        <>
            <AdmissionBanner />
            <div className="container mb-5 mt-5">
                <div className="mb-3">
                    <p className="text-muted admission_description">
                        Riverdale School is now accepting online applications. To secure your child’s place, please complete the online application form. All applicants must follow the same initial admission process.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted admission_description">
                        Due to COVID-19 guidelines, our school is currently closed. We are offering an online application system. For more information, contact our Admissions Manager:
                        <br />
                        Main Branch: +880244612414, +8801552356559
                        <br />
                        Branch 2: +8801821467266
                        <br />
                        Email: riverdale@gmail.com
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted admission_description">Online payment options are available.</p>
                </div>
                <div className="mb-3">
                    <h2 className="admission_step_header">STEP 1</h2>
                    <p className="text-muted admission_list_step">Complete the online application form and payment.</p>
                </div>
                <div className="mb-3">
                    <h2 className="admission_step_header">STEP 2</h2>
                    <p className="text-muted about_description">Submit the following documents to the Riverdale School Admissions Office:</p>
                    <ul className='admission_step_2_ul'>
                        <li className="text-muted admission_list_step">Student's Birth Certificate</li>
                        <li className="text-muted admission_list_step">Parent's ID Copy</li>
                        <li className="text-muted admission_list_step">Student Immunization Record</li>
                        <li className="text-muted admission_list_step">4 Passport-sized Photographs of the Student</li>
                        <li className="text-muted admission_list_step">Previous Year’s School Reports</li>
                        <li className="text-muted admission_list_step">Original Transfer Certificate*</li>
                    </ul>
                    <p className="text-muted about_description">Application Fee: BDT (XXX) (non-refundable).</p>
                </div>
                <div className="mb-3">
                    <h2 className="admission_step_header">STEP 3</h2>
                    <ul className='admission_step_3_ul'>
                        <li className="text-muted admission_list_step">Upon receipt of all required documents and application fee, you will be contacted by the Admissions Office to arrange an Entrance Test.</li>
                        <li className="text-muted admission_list_step">Applicants will undertake the Entrance Test and may be invited for an interview (if required).</li>
                        <li className="text-muted admission_list_step">Entrance Tests are conducted based on available slots in the respective year group.</li>
                    </ul>
                </div>
                <div className="mb-3">
                    <h2 className="admission_step_header">STEP 4</h2>
                    <p className="text-muted about_description">After the Entrance Test, the Admissions Office will contact you within 5 to 7 working days. The outcome will be one of the following:</p>
                    <ul className='admission_step_4_ul'>
                        <li className="text-muted admission_list_step">Placement Confirmation</li>
                        <li className="text-muted admission_list_step">Conditional Offer</li>
                        <li className="text-muted admission_list_step">Waiting List</li>
                        <li className="text-muted admission_list_step">Re-Entrance Tests</li>
                        <li className="text-muted admission_list_step">Not Accepted</li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default OnlineAdmissionProcess;
