import React from 'react';
import ExtraCarricularActivitiesBanner from './ExtraCarricularActivitiesBanner';

const ExtraCarricularActivities = () => {
    return (
        <>
            <ExtraCarricularActivitiesBanner />
            <div className="container mb-5 mt-5">
                {/* Real World Learning Section */}
                <div className="mb-3">
                    <h4 className="header_curriculum_overview">REAL WORLD LEARNING</h4>
                    <p className="text-muted curriculum_rds_description">
                        Our Extra-Curricular Activities (ECA) program is designed to nurture creativity, moral values, and communication skills in students. Through engaging, hands-on activities, students not only gain practical life skills but also discover their unique interests and talents. 
                    </p>
                    <p className="text-muted curriculum_rds_description">
                        By participating in ECA, students collaborate with peers, enriching their life experiences and boosting their confidence. Every child’s potential is unique, and our student-centered ECA projects aim to bring out the best in each individual. At RDS, we strive to provide a safe, stimulating environment that encourages creativity and skill development.
                    </p>
                </div>

                {/* School Programs - Clubs Section */}
                <div className="mb-3">
                    <h4 className="header_curriculum_areas">SCHOOL PROGRAM</h4>
                    <h5 className="text-muted">Clubs</h5>
                    <ul className="admission_step_3_ul">
                        <li className="text-muted admission_list_step">Taekwondo Club</li>
                        <li className="text-muted admission_list_step">Bangla Debate Club</li>
                        <li className="text-muted admission_list_step">Art and Paper Craft Club</li>
                    </ul>
                </div>

                {/* After School Program - Paid Program Section */}
                <div className="mb-3">
                    <h4 className="header_curriculum_areas">AFTER SCHOOL PROGRAM (PAID PROGRAM)</h4>
                    <ul className="admission_step_3_ul">
                        <li className="text-muted admission_list_step">Extra-Curricular Activities</li>
                        <li className="text-muted admission_list_step">Fees and Charges</li>
                        <li className="text-muted admission_list_step">Schedule</li>
                        <li className="text-muted admission_list_step">ECA Faculty</li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default ExtraCarricularActivities;
