import React from 'react';
import Syllabus_1674 from '../../Images/Syllabus_1674.png';
import Syllabus from './Syllabus';

const SyllabusBanner = () => {
    // const syllabusData = [
    //     {
    //         img: '../../Images/Like.png',
    //         name: 'Playgroup Syllabus',
    //     },
    //     {
    //         img: '../../Images/Like.png',
    //         name: 'Nursery Syllabus',
    //     },
    //     {
    //         img: '../../Images/Like.png',
    //         name: 'KG-1 Syllabus',
    //     },
    //     {
    //         img: '../../Images/Like.png',
    //         name: 'KG-2 Syllabus',
    //     },
    //     {
    //         img: '../../Images/Like.png',
    //         name: 'Class-1 Syllabus',
    //     },
    //     {
    //         img: '../../Images/Like.png',
    //         name: 'Class-2 Syllabus',
    //     },
    //     {
    //         img: '../../Images/Like.png',
    //         name: 'Class-3 Syllabus',
    //     },
    //     {
    //         img: '../../Images/Like.png',
    //         name: 'Class-4 Syllabus',
    //     },
    //     {
    //         img: '../../Images/Like.png',
    //         name: 'Class-5 Syllabus',
    //     },
    //     {
    //         img: '../../Images/Like.png',
    //         name: 'Class-6 Syllabus',
    //     },
    //     {
    //         img: '../../Images/Like.png',
    //         name: 'Class-7 Syllabus',
    //     },
    //     {
    //         img: '../../Images/Like.png',
    //         name: 'Class-8 Syllabus',
    //     },
    // ]
    return (
        <>
            <div class="banner_about_us">
                <img src={Syllabus_1674} className="img-fluid" alt="Syllabus_1674" />
                <div class="content_img">
                    <div class="container d-flex justify-content-start">
                        <h1 class="text-white">Syllabus of RDS</h1>
                    </div>
                </div>
            </div>
            {/* <div className='container mb-5 mt-5'>

                {syllabusData.map((name) => (
                    <Syllabus name={name}/>
                ))}
            </div> */}
        </>
    );
};

export default SyllabusBanner;