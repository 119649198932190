import React from 'react';
import HomeBanner from '../../Images/HomeBanner.png';

const Banner = () => {
    return (
        <div>
            <img src={HomeBanner} className="img-fluid" alt="HomeBanner" />
            {/* <div class="header_banner">
                <h2 className=''>Better Future</h2>
                <h2 className=''>For Your Kids</h2>
            </div> */}
        </div>
    );
};

export default Banner;