import React from 'react';
import AboutBanner from '../../Images/AboutBanner.png';


const AboutUsBanner = () => {
    return (
        <>
            <div class="banner_about_us">
                <img src={AboutBanner} className="img-fluid" alt="AboutBanner" />
                <div class="content_img">
                    <div class="container d-flex justify-content-start">
                        <h1 class="text-white">About us</h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUsBanner;