import React from 'react';
import Banner from './Banner';
import RiverdaleSchoolChoose from '../../Images/RiverdaleSchoolChoose.png';
import RiverdaleSchool from '../../Images/RiverdaleSchool.png';
import Slider from '../slider/Slider';
import Gallery from './Gallery';
import CarouselSlider from '../carousel_slider/CarouselSlider';

const MainHomePage = () => {
    return (
        <>
            <Banner />
            <div className="container mb-4 mt-5">
                <div className="row g-3">
                    <div className="col-md-6">
                        <h3 className="header_who">Welcome to</h3>
                        <h2 className="header_about">Riverdale School</h2>
                        <p className="text-muted about_description pe-md-5">
                            Et culpa irure occaecat enim tempor laborum. Id anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt nostrud anim voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing incididunt nisi. Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim anim exercitation Lorem consectetur commodo.
                        </p>
                        <div className="pt-3">
                            <button className="btn btn_learn_more">Learn More</button>
                        </div>
                    </div>
                    <div className="col-md-6 pt-2">
                        <img src={RiverdaleSchool} className="img-fluid" alt="Riverdale School" style={{ width: '100%', height: '280px' }} />
                    </div>
                </div>

                <div className="row g-3 mt-5">
                    <div className="col-md-6">
                        <img src={RiverdaleSchoolChoose} className="img-fluid" alt="Why Choose Riverdale School" style={{ width: '100%', height: '280px' }} />
                    </div>
                    <div className="col-md-6 text-end">
                        <h3 className="header_who">Why Choose</h3>
                        <h2 className="header_about">Riverdale School</h2>
                        <p className="text-muted school_description ps-md-5">
                            Et culpa irure occaecat enim tempor laborum. Id anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt nostrud anim voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing incididunt nisi. Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim anim exercitation Lorem consectetur commodo.
                        </p>
                    </div>
                </div>

                <div className="row g-3 mt-5">
                    <div className="col-md-12">
                        <h3 className="header_latest_event">Latest Event</h3>
                        <p className="text-muted latest_event_description px-md-5">
                            Et culpa irure occaecat enim tempor laborum. Id anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt nostrud anim voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing incididunt nisi. Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim anim exercitation Lorem consectetur commodo.
                        </p>
                    </div>
                </div>

                {/* Uncomment this when needed */}
                {/* <div className="row g-3 mt-5">
                    <Slider />
                </div> */}

                <div className="row g-3 mt-5">
                    <CarouselSlider />
                </div>
                
                <div className="row g-3 mt-5">
                    <Gallery />
                </div>
            </div>
        </>
    );
};

export default MainHomePage;
