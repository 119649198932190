import React from 'react';
import Mission from '../../Images/Mission.png';
import Vision from '../../Images/Vision.png';
import AboutUsBanner from './AboutUsBanner';

const AboutUs = () => {
    return (
        <>
            <AboutUsBanner />
            <div className="container mb-4 mt-5">
                <div className="mt-0">
                    <h3 className="header_about_our_sec">
                        <span className="header_about_our">Welcome to</span>
                        <span className="header_about"> Riverdale School</span>
                        <span className="header_at"> – A Journey of Excellence</span>
                    </h3>
                    <h2 className="header_about_riverdale">Our Story</h2>
                    <p className="text-muted about_description">
                        At Riverdale School, we’ve nurtured generations of leaders, innovators, and visionaries. 
                        Our journey began with a dream to provide holistic education, where academic excellence 
                        meets personal growth. Over the years, we have created an environment that not only inspires learning 
                        but also fosters a deep sense of community and responsibility. We are proud to continue this tradition 
                        of nurturing young minds, ensuring every student leaves with the skills and values to thrive in a global world.
                    </p>
                </div>

                <div className="row g-3 mt-5">
                    <div className="col-md-6">
                        <img src={Mission} className="img-fluid rounded-sm" style={{ width: '100%', height: '280px' }} alt="Mission" />
                    </div>
                    <div className="col-md-6">
                        <div className="ps-md-5">
                            <h3 className="header_our_mission">Our Mission</h3>
                            <p className="text-muted our_mission_description">
                                Riverdale School is committed to empowering students to reach their highest potential 
                                through a rigorous academic curriculum, innovative teaching, and a supportive environment. 
                                Our mission is to cultivate future leaders who are not only intellectually capable but 
                                also ethically grounded, with a passion for lifelong learning and a desire to make the world a better place.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row g-3 mt-5">
                    <div className="col-md-6">
                        <h3 className="header_our_vision">Our Vision</h3>
                        <p className="text-muted our_vision_description pe-md-5">
                            At Riverdale, our vision is to be a school where students flourish both academically and personally. 
                            We aspire to create a dynamic learning environment that prepares students to face the challenges of the 21st century. 
                            Through our forward-thinking approach, we aim to inspire curiosity, innovation, and compassion—instilling in every student 
                            the drive to positively impact the world.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={Vision} className="img-fluid rounded-sm" style={{ width: '100%', height: '280px' }} alt="Vision" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUs;
