import React from 'react';
import Academic_Banner from '../../Images/Academic_Banner.png';


const AcademicProgramBanner = () => {
    return (
        <>
            <div class="banner_academic_program">
                <img src={Academic_Banner} className="img-fluid" alt="Academic_Banner" />
                <div class="content_img">
                    <div class="container d-flex justify-content-start">
                        <h1 class="text-white">Academic Program</h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AcademicProgramBanner;