import React from 'react';
import AlumniBanner from './AlumniBanner';

const Alumni = () => {
    return (
        <>
            <AlumniBanner />
            <div className="container mb-5 mt-5">
                <div className="mb-3">
                    <p className="text-muted alumni_description">
                        Riverdale School Alumni was founded to provide a platform for graduates and past students to connect and reconnect with old friends. We aim to foster discussions, share interesting stories, and collaborate on productive projects, all in the spirit of friendship and nostalgia. Our reunions and events bridge the gap between the old and the new, promoting unity among alumni members.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted alumni_description">
                        At Riverdale School Alumni, we celebrate greatness. Greatness is not reserved for a select few but is a collective pursuit. It encompasses not only our scholastic achievements but also our commitment to embodying the core ideals and values of our school. Greatness is not confined to a specific place or person but is wherever someone is striving to find it. Continue your journey with us and be part of the Riverdale legacy, for once a Riverdaleian, always a Riverdaleian.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted alumni_description">
                        For inquiries about Riverdale School Alumni membership and activities, please contact us via:
                    </p>
                    <p className="text-muted alumni_description">
                        Email: <a href="mailto:riverdaleschoolalumni@gmail.com">riverdaleschoolalumni@gmail.com</a>
                    </p>
                    <p className="text-muted alumni_description">
                        Facebook: <a href="https://www.facebook.com/RiverdaleInternationalSchool" target="_blank" rel="noopener noreferrer">Riverdale International School</a>
                    </p>
                </div>
            </div>
        </>
    );
};

export default Alumni;
