// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
// import './App.css';
// import { Routes, Route } from 'react-router-dom';
// import Layouts from './Components/layouts/Layouts';
// import MainHomePage from './Components/mainHomePage/MainHomePage';
// import NotFound from './Components/NotFound/NotFound';

// function App() {
//   return (
//       <Routes>
//         <Route path="/" element={<Layouts/>}>
//           <Route index element={<MainHomePage/>}></Route>
//           <Route path="home" element={<Home/>}></Route>
//           <Route path="about" element={<About/>}></Route>
//           <Route path="education" element={<Education/>}></Route>
//           <Route path="projects" element={<Projects/>}></Route>
//           <Route path="skills" element={<Skills/>}></Route>
//           <Route path="exprience" element={<Exprience/>}></Route>
//           <Route path="contact" element={<Contact/>}></Route>
//           <Route path="*" element={<NotFound />} />
//         </Route> 
//       </Routes>
//   );
// }

// export default App;


import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import NotFound from './Components/NotFound/NotFound';
import { Routes, Route } from 'react-router-dom';
import Layouts from './Components/layouts/Layouts';
import MainHomePage from './Components/main_home_page/MainHomePage';
import Home from './Components/Home/Home';
import Notice from './Components/notice/Notice';
import AcademicProgram from './Components/academics/AcademicProgram';
import CurriculumOverview from './Components/academics/CurriculumOverview';
import OutdoorClassroom from './Components/academics/OutdoorClassroom';
import ExtraCarricularActivities from './Components/academics/ExtraCarricularActivities';
import Syllabus from './Components/academics/Syllabus';
import ContactUs from './Components/contact_us/ContactUs';
import PayOnline from './Components/pay_online/PayOnline';
import NewsEvent from './Components/info_center/NewsEvent';
import OnlineAdmissionProcess from './Components/admission/OnlineAdmissionProcess';
import AboutUs from './Components/about_us/AboutUs';
import AdmissionForm from './Components/admission/AdmissionForm';
import InfoGallery from './Components/info_center/InfoGallery';
import Alumni from './Components/info_center/Alumni';
import SchoolCalendar from './Components/info_center/SchoolCalendar';
// import SyllabusPlayGroup from './Components/academics/syllabus/SyllabusPlayGroup';
import SyllabusDetails from './Components/academics/syllabus_pdf/SyllabusDetails';


function App() {
  return (
      <Routes>
        {/* <Route path="/" element={<Header/>}></Route> */}
        <Route path="/" element={<Layouts/>}>
          <Route index element={<MainHomePage/>}></Route>
          <Route index element={<Home/>}></Route>
          <Route path="about-us" element={<AboutUs/>}></Route>
          <Route path="online-admission-process" element={<OnlineAdmissionProcess/>}></Route>
          <Route path="admission-form" element={<AdmissionForm/>}></Route>
          <Route path="notice" element={<Notice/>}></Route>
          <Route path="academic-program" element={<AcademicProgram/>}></Route>
          <Route path="curriculum-overview" element={<CurriculumOverview/>}></Route>
          <Route path="outdoor-classroom" element={<OutdoorClassroom/>}></Route>
          <Route path="extra-carricular-activities" element={<ExtraCarricularActivities/>}></Route>
          <Route path="syllabus" element={<Syllabus/>}></Route>
          <Route path="syllabus" element={<Syllabus/>}>
            {/* <Route path="play-group-syllabus" element={<SyllabusPlayGroup/>}></Route> */}
          </Route>
          <Route path="play-group-syllabus" element={<SyllabusDetails/>}></Route>
          <Route path="academic-program" element={<AcademicProgram/>}></Route>
          <Route path="news-event" element={<NewsEvent/>}></Route>
          <Route path="gallery" element={<InfoGallery/>}></Route>
          <Route path="alumni" element={<Alumni/>}></Route>
          <Route path="school-calendar" element={<SchoolCalendar/>}></Route>
          <Route path="contact-us" element={<ContactUs/>}></Route>
          <Route path="pay-online" element={<PayOnline/>}></Route>


          {/* <Route index element={<Footer/>}></Route> */}
          <Route path="*" element={<NotFound />} />
        </Route> 
      </Routes>
  );
}

export default App;