import React from 'react';
import MaskGroup from '../../Images/MaskGroup.png';
import MaskGroup_1 from '../../Images/MaskGroup_1.png';
import MaskGroup_2 from '../../Images/MaskGroup_2.png';
import MaskGroup_3 from '../../Images/MaskGroup_3.png';
import MaskGroup_4 from '../../Images/MaskGroup_4.png';
import MaskGroup_5 from '../../Images/MaskGroup_5.png';
import MaskGroup_6 from '../../Images/MaskGroup_6.png';
import MaskGroup_7 from '../../Images/MaskGroup_7.png';
// import MaskGroup_8 from '../../Images/MaskGroup_8.png';

const Gallery = () => {
    return (
        <>
            <div class="container">
                <h3 className="header_gallery ">Gallery</h3>
                <div class="row g-2">
                    <div class="col-md-3">
                        <div class="p-3">
                            <img src={MaskGroup} class="card-img-top" alt="MaskGroup" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="p-3">
                            <img src={MaskGroup_1} class="card-img-top" alt="MaskGroup_1" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="p-3">
                            <img src={MaskGroup_2} class="card-img-top" alt="MaskGroup_2" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="p-3">
                            <img src={MaskGroup_3} class="card-img-top" alt="MaskGroup_3" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="p-3">
                            <img src={MaskGroup_4} class="card-img-top" alt="MaskGroup_4" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="p-3">
                            <img src={MaskGroup_5} class="card-img-top" alt="MaskGroup_5" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="p-3">
                            <img src={MaskGroup_6} class="card-img-top" alt="MaskGroup_6" />
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="p-3">
                            <img src={MaskGroup_7} class="card-img-top" alt="MaskGroup_7" />
                        </div>
                    </div>
                </div>
                <div className="btn_gallery_load_more ">
                    <a src='#' className="btn btn_load_more" >Load more</a>
                </div>
            </div>
        </>
    );
};

export default Gallery;