import React from 'react';
import CarouselSlider from '../carousel_slider/CarouselSlider';
import Gallery from '../main_home_page/Gallery';
import NewsEventBanner from './NewsEventBanner';

const NewsEvent = () => {
    return (
        <>
            <NewsEventBanner />
            <div className="container mb-5 mt-5">
                <div className="row g-3 mt-5">
                    <div className="col-md-12">
                        <h3 className="header_latest_event">Latest News & Events</h3>
                        <p className="text-muted latest_event_description px-md-5">
                            Et culpa irure occaecat enim tempor laborum. Id anim sint occaecat amet laboris pariatur aute ipsum. Laborum eu Lorem consequat sit deserunt nostrud anim voluptate est sint sunt enim. Fugiat cillum est sint ipsum nulla magna adipisicing incididunt nisi. Qui amet ex elit in veniam sunt. Non velit culpa occaecat nisi laborum anim anim exercitation Lorem consectetur commodo.
                        </p>
                    </div>
                </div>
                <div className="row g-3 mt-5">
                    <CarouselSlider />
                </div>
                <div className="row g-3 mt-5">
                    <Gallery />
                </div>
            </div>
        </>
    );
};

export default NewsEvent;
