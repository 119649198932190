import React from 'react';
import PayBanner from '../../Images/PayBanner.png';


const PayOnlineBanner = () => {
    return (
        <>
        <div class="banner_pay_online">
            <img src={PayBanner} className="img-fluid" alt="PayBanner" />
            <div class="content_img">
                <div class="container d-flex justify-content-start">
                    <h1 class="text-white">Payment Online</h1>
                </div>
            </div>
        </div>
    </>
    );
};

export default PayOnlineBanner;