import React from 'react';
import Outdoor_Classroom_Banner from '../../Images/Outdoor_Classroom_Banner.png';

const OutdoorClassroomBanner = () => {
    return (
        <>
            <div class="banner_about_us">
                <img src={Outdoor_Classroom_Banner} className="img-fluid" alt="Outdoor_Classroom_Banner" />
                <div class="content_img">
                    <div class="container d-flex justify-content-start">
                        <h1 class="text-white">The Outdoor Classroom</h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OutdoorClassroomBanner;