import React from 'react';
import SyllabusBanner from './SyllabusBanner';
import Card_Syllabus from '../../Images/Card_Syllabus.png';
// import SyllabusPlayGroup from './syllabus/SyllabusPlayGroup';

const Syllabus = () => {

    return (
        <>
            <SyllabusBanner />
            <div className='container mb-5 mt-5'>
                <div className="row g-4">
                    <div className="col-md-3">
                        <div className="card h-100 text-center bg_card_syllabus">
                            <div className="pt-4">
                                <img src={Card_Syllabus} className="rounded" style={{ width: '50px', height: '50px' }} alt="Card_Syllabus" />
                            </div>
                            <div className="card-body ">
                                <a href="play-group-syllabus" className="text-decoration-none link_syllabus" >Playgroup Syllabus</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card h-100 text-center bg_card_syllabus">
                            <div className="pt-4">
                                <img src={Card_Syllabus} className="rounded" style={{ width: '50px', height: '50px' }} alt="Card_Syllabus" />
                            </div>
                            <div className="card-body ">
                                <a href="#" className="text-decoration-none link_syllabus">Nursery Syllabus</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card h-100 text-center bg_card_syllabus">
                            <div className="pt-4">
                                <img src={Card_Syllabus} className="rounded" style={{ width: '50px', height: '50px' }} alt="Card_Syllabus" />
                            </div>
                            <div className="card-body ">
                                <a href="#" className="text-decoration-none link_syllabus">KG-1 Syllabus</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card h-100 text-center bg_card_syllabus">
                            <div className="pt-4">
                                <img src={Card_Syllabus} className="rounded" style={{ width: '50px', height: '50px' }} alt="Card_Syllabus" />
                            </div>
                            <div className="card-body ">
                                <a href="#" className="text-decoration-none link_syllabus">KG-2 Syllabus</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card h-100 text-center bg_card_syllabus">
                            <div className="pt-4">
                                <img src={Card_Syllabus} className="rounded" style={{ width: '50px', height: '50px' }} alt="Card_Syllabus" />
                            </div>
                            <div className="card-body ">
                                <a href="#" className="text-decoration-none link_syllabus">Class-1 Syllabus</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card h-100 text-center bg_card_syllabus">
                            <div className="pt-4">
                                <img src={Card_Syllabus} className="rounded" style={{ width: '50px', height: '50px' }} alt="Card_Syllabus" />
                            </div>
                            <div className="card-body ">
                                <a href="#" className="text-decoration-none link_syllabus">Class-2 Syllabus</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card h-100 text-center bg_card_syllabus">
                            <div className="pt-4">
                                <img src={Card_Syllabus} className="rounded" style={{ width: '50px', height: '50px' }} alt="Card_Syllabus" />
                            </div>
                            <div className="card-body ">
                                <a href="#" className="text-decoration-none link_syllabus">Class-3 Syllabus</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card h-100 text-center bg_card_syllabus">
                            <div className="pt-4">
                                <img src={Card_Syllabus} className="rounded" style={{ width: '50px', height: '50px' }} alt="Card_Syllabus" />
                            </div>
                            <div className="card-body ">
                                <a href="#" className="text-decoration-none link_syllabus">Class-4 Syllabus</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card h-100 text-center bg_card_syllabus">
                            <div className="pt-4">
                                <img src={Card_Syllabus} className="rounded" style={{ width: '50px', height: '50px' }} alt="Card_Syllabus" />
                            </div>
                            <div className="card-body ">
                                <a href="#" className="text-decoration-none link_syllabus">Class-5 Syllabus</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card h-100 text-center bg_card_syllabus">
                            <div className="pt-4">
                                <img src={Card_Syllabus} className="rounded" style={{ width: '50px', height: '50px' }} alt="Card_Syllabus" />
                            </div>
                            <div className="card-body ">
                                <a href="#" className="text-decoration-none link_syllabus">Class-6 Syllabus</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card h-100 text-center bg_card_syllabus">
                            <div className="pt-4">
                                <img src={Card_Syllabus} className="rounded" style={{ width: '50px', height: '50px' }} alt="Card_Syllabus" />
                            </div>
                            <div className="card-body ">
                                <a href="#" className="text-decoration-none link_syllabus">Class-7 Syllabus</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card h-100 text-center bg_card_syllabus">
                            <div className="pt-4">
                                <img src={Card_Syllabus} className="rounded" style={{ width: '50px', height: '50px' }} alt="Card_Syllabus" />
                            </div>
                            <div className="card-body ">
                                <a href="#" className="text-decoration-none link_syllabus">Class-8 Syllabus</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>
    );
};

export default Syllabus;



// import React from 'react';
// import SyllabusBanner from './SyllabusBanner';
// import Vision from '../../Images/Vision.png';

// const Syllabus = () => {
//     // const names = ['Bruce', 'Clark', 'Diana'];
//     const syllabusData = [
//         {
//             photo: {Vision},
//             name: 'Playgroup Syllabus',
//         },
//         {
//             photo: {Vision},
//             name: 'Nursery Syllabus',
//         },
//         {
//             photo: {Vision},
//             name: 'KG-1 Syllabus',
//         },
//         {
//             photo: {Vision},
//             name: 'KG-2 Syllabus',
//         },
//         {
//             photo: {Vision},
//             name: 'Class-1 Syllabus',
//         },
//         {
//             photo: {Vision},
//             name: 'Class-2 Syllabus',
//         },
//         {
//             photo: {Vision},
//             name: 'Class-3 Syllabus',
//         },
//         {
//             photo: {Vision},
//             name: 'Class-4 Syllabus',
//         },
//         {
//             photo: {Vision},
//             name: 'Class-5 Syllabus',
//         },
//         {
//             photo: {Vision},
//             name: 'Class-6 Syllabus',
//         },
//         {
//             photo: {Vision},
//             name: 'Class-7 Syllabus',
//         },
//         {
//             photo: {Vision},
//             name: 'Class-8 Syllabus',
//         },
//     ]
//     return (
//         <>
//             <SyllabusBanner />
//             <div>
//                 {syllabusData.map((e,i) => 
//                     <>
//                         <div className='container mb-5 mt-5'>
//                             <div className="row g-4">
//                                 <div className="col-md-3">
//                                     <div className="card h-100 text-center bg_card_syllabus">
//                                         <div className="pt-4">
//                                             <img src={e.photo.Vision} className="rounded" style={{ width: '50px', height: '50px' }} alt="Vision" />
//                                         </div>
//                                         <div className="card-body ">
//                                             <a href="play-group-syllabus" className="text-decoration-none link_syllabus" >{e.name}  key={i}</a>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </>
//                     )}
//             </div>
            
//         </>

//     )
// }
// export default Syllabus;

{/* <h2>name: {e.name}  key={i}</h2>
<div>
    <img src={e.photo.Vision} alt='akka' />
</div> */}