import React from 'react';
import { useState } from 'react'
// //Import the main component
import { Viewer } from '@react-pdf-viewer/core'; // install this library
//// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
// //Import the styles

import '../../../../node_modules/@react-pdf-viewer/core/lib/styles/index.css';
import '../../../../node_modules/@react-pdf-viewer/default-layout/lib/styles/index.css';
// //Worker
import { Worker } from '@react-pdf-viewer/core'; // install this library
import notice_pdf from './notice_pdf.pdf';
// import SyllabusPlayGroup from './syllabus/SyllabusPlayGroup';


const SyllabusPlayGroup = () => {
    const [defauldPdffile] = useState(notice_pdf);
    // Create new plugin instance
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
   
    return (
        <div>
            {defauldPdffile && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
                <Viewer fileUrl={defauldPdffile}
                    plugins={[defaultLayoutPluginInstance]} />
            </Worker></>}
        </div>
    );
};

export default SyllabusPlayGroup;