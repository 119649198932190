import React from 'react';
import Extra_Carricular_Activities_Banner from '../../Images/Extra_Carricular_Activities_Banner.png';

const ExtraCarricularActivitiesBanner = () => {
    return (
        <>
            <div class="banner_about_us">
                <img src={Extra_Carricular_Activities_Banner} className="img-fluid" alt="Extra_Carricular_Activities_Banner" />
                <div class="content_img">
                    <div class="container d-flex justify-content-start">
                        <h1 class="text-white">Extra Carricular Activities</h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ExtraCarricularActivitiesBanner;