import React from 'react';
import PdfViewer from '../info_center/PdfViewer';
import NoticeBanner from './NoticeBanner';

const Projects = () => {
    return (
        <>
           <NoticeBanner/> 
           {/* <PdfViewer/> */}
        </>
    );
};

export default Projects;