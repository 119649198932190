import React from 'react';
import QRCode from '../../Images/QRCode.png';
import PayOnlineBanner from './PayOnlineBanner';


const PayOnline = () => {
    return (
        <>
            <PayOnlineBanner />
            <div className="container mb-5 mt-5">
                <div className="row g-3 ">
                    <div className="col-md-7">
                        <div className="mt-0">
                            <h2 className="header_payment_steps mb-3">Payment Steps</h2>
                            <p className="text-muted payment_steps_description pe-md-5">You can make payments from
                                your bKash Account to any “Merchant” who accepts “bKash Payment”. Now you can
                                bKash your Payment at more than 47,000 outlets nationwide. To bKash your
                                Payment follow the steps below.
                            </p>
                            <ul className='payment_steps_ul'>
                                <li className="text-muted payment_steps_list">Go to your bKash Mobile Menu by dialing *247#</li>
                                <li className="text-muted payment_steps_list">Choose “Payment”</li>
                                <li className="text-muted payment_steps_list">Enter the Merchant bKash Account Number 01730730102</li>
                                <li className="text-muted payment_steps_list">Enter the amount you want to pay</li>
                                <li className="text-muted payment_steps_list">Enter a reference* against your payment (Please mention Student ID, Name and Branch)</li>
                                <li className="text-muted payment_steps_list">Enter the Counter Number 0</li>
                                <li className="text-muted payment_steps_list">Now enter your bKash Mobile Menu PIN to confirm</li>
                            </ul>
                            <p className="text-muted payment_steps_done">Done! You will receive a confirmation message from bKash.</p>
                        </div>
                    </div>
                    <div className="col-md-5 pt-2 ">
                        <h2 className="header_payment_scan_qr">Scan QR</h2>
                        <img src={QRCode} className=" img-fluid " style={{ width: '80%', height: '320px' }} alt="QRCode" border="0" />
                    </div>
                </div>
                <p className="payment_steps_send">** Send us the payment details (Phone No, Transaction ID) to our email. admin@riverdale.info **</p>
            </div>
        </>
    );
};

export default PayOnline;