import React from 'react';
import AlumniBaner from '../../Images/AlumniBaner.png';


const AlumniBanner = () => {
    return (
        <>
            <div class="banner_alumni">
                <img src={AlumniBaner} className="img-fluid" alt="AlumniBaner" />
                <div class="content_img">
                    <div class="container d-flex justify-content-start">
                        <h1 class="text-white">Alumni</h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AlumniBanner;