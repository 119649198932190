// // import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from "react-router-dom";
// // import logo from '../../Images/logo.png';
// import LOGO_01 from '../../Images/LOGO_01.png';

// const Header = () => {
//     // const [menuOpen, setMenuOpen] = useState(false);
//     // const [size, setSize] =useState({
//     //     width: undefined,
//     //     hight: undefined,
//     // });
//     const navigate = useNavigate();
//     const headerClickHandler = e => {
//         e.preventDefault()
//         // navigate(-1);
//         navigate("/")
//     };

//     // useEffect(() => {
//     //     const handleResize = () => {
//     //         setSize()({
//     //             width: window.innerWidth,
//     //             height: window.innerHeight,
//     //         });
//     //     };
//     //     window.addEventListener("resize", handleResize);
//     // }, []);

//     // useEffect(() => {
//     //     if(size.width > 768 && menuOpen){
//     //         setMenuOpen(false);
//     //     }
//     // }, [size.width, menuOpen]);

//     // const menuToggleHandler = () => {
//     //     setMenuOpen((p) =>!p);
//     // };


//     return (
//         <nav class="navbar navbar-expand-lg navbar-light bg-light">
//             <div class="container-fluid">
//                 <Link to="/" className="navbar-brand" >
//                     <img src={LOGO_01} className="img-fluid" style={{ width: '90px', height: '50px' }} alt="LOGO_01" border="0" />
//                 </Link>
//                 <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//                     <span class="navbar-toggler-icon"></span>
//                 </button>
//                 <div class="collapse navbar-collapse" id="navbarSupportedContent">
//                     <ul class="navbar-nav me-auto mb-2 mb-lg-0">
//                         <li class="nav-item">
//                             <Link to='/' className="nav-link active" aria-current="page">Home</Link>
//                         </li>
//                         <li class="nav-item">
//                         <Link to='about-us' className="nav-link">About Us</Link>
//                         </li>
//                         <li class="nav-item dropdown">
//                             <Link class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//                                 Admission
//                             </Link>
//                             <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
//                                 <li>
//                                     <Link to="online-admission-process" className="dropdown-item">Online Application Process</Link>
//                                 </li>
//                                 <li>
//                                     <Link to="admission-form" className="dropdown-item">Application Form</Link>
//                                 </li>
//                             </ul>
//                         </li>
//                     </ul>
//                 </div>
//             </div>
//         </nav>
//     );
// };

// export default Header;



import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
// import logo from '../../Images/logo.png';
import LOGO_01 from '../../Images/LOGO_01.png';

const Header = () => {
    // const [menuOpen, setMenuOpen] = useState(false);
    // const [size, setSize] =useState({
    //     width: undefined,
    //     hight: undefined,
    // });
    const navigate = useNavigate();
    const headerClickHandler = e => {
        e.preventDefault()
        // navigate(-1);
        navigate("/")
    };

    // useEffect(() => {
    //     const handleResize = () => {
    //         setSize()({
    //             width: window.innerWidth,
    //             height: window.innerHeight,
    //         });
    //     };
    //     window.addEventListener("resize", handleResize);
    // }, []);

    // useEffect(() => {
    //     if(size.width > 768 && menuOpen){
    //         setMenuOpen(false);
    //     }
    // }, [size.width, menuOpen]);

    // const menuToggleHandler = () => {
    //     setMenuOpen((p) =>!p);
    // };


    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container container-fluid">
                <Link to="/" className="navbar-brand" >
                    <img src={LOGO_01} className="img-fluid" style={{ width: '90px', height: '50px' }} alt="LOGO_01" border="0" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <span onClick={headerClickHandler}></span>

                    <ul className="navbar-nav">
                        <li className="nav-item mx-1">
                            <Link to='/' className="nav-link active" aria-current="page">Home</Link>
                        </li>
                        <li className="nav-item mx-1">
                            <Link to='about-us' className="nav-link">About Us</Link>
                        </li>
                        <li className="nav-item dropdown mx-1">
                            <Link to='#' className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">Admission</Link>

                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown1">
                                <li class="nav-item">
                                    <Link to="online-admission-process" className="dropdown-item nav-link">Online Application Process</Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="admission-form" className="dropdown-item nav-link">Application Form</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown mx-1">
                            <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">Academics</Link>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown2">
                                <li class="nav-item">
                                    <Link to="academic-program" className="dropdown-item nav-link">Academic Program</Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="curriculum-overview" className="dropdown-item nav-link">Curriculum Overview</Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="outdoor-classroom" className="dropdown-item nav-link">The Outdoor Classroom</Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="extra-carricular-activities" className="dropdown-item nav-link">Extra Carricular Activities</Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="syllabus" className="dropdown-item nav-link">Syllabus</Link>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item mx-1">
                            <Link to='notice' className="nav-link" >Notice</Link>
                        </li>
                        <li className="nav-item dropdown mx-1">
                            <Link to='#' className="nav-link dropdown-toggle" id="navbarDropdown3" role="button" data-bs-toggle="dropdown" aria-expanded="false">Info Center</Link>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown3">
                                <li class="nav-item">
                                    <Link to="news-event" className="dropdown-item nav-link">News & Event</Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="gallery" className="dropdown-item nav-link">Gallery</Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="alumni" className="dropdown-item nav-link">Alumni</Link>
                                </li>
                                <li class="nav-item">
                                    <Link to="school-calendar" className="dropdown-item nav-link">School Calendar</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item mx-1">
                            <Link to='contact-us' className="nav-link" >Contact us</Link>
                        </li>
                        <li className="nav-item mx-1">
                            <Link to='pay-online' className="nav-link btn_learn_more nav_link_pay_online" >Pay Online</Link>
                        </li>
                    </ul>

                </div>
            </div>
        </nav>
    );
};

export default Header;



// // import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from "react-router-dom";
// // import logo from '../../Images/logo.png';
// import LOGO_01 from '../../Images/LOGO_01.png';

// const Header = () => {
//     // const [menuOpen, setMenuOpen] = useState(false);
//     // const [size, setSize] =useState({
//     //     width: undefined,
//     //     hight: undefined,
//     // });
//     const navigate = useNavigate();
//     const headerClickHandler = e => {
//         e.preventDefault()
//         // navigate(-1);
//         navigate("/")
//     };

//     // useEffect(() => {
//     //     const handleResize = () => {
//     //         setSize()({
//     //             width: window.innerWidth,
//     //             height: window.innerHeight,
//     //         });
//     //     };
//     //     window.addEventListener("resize", handleResize);
//     // }, []);

//     // useEffect(() => {
//     //     if(size.width > 768 && menuOpen){
//     //         setMenuOpen(false);
//     //     }
//     // }, [size.width, menuOpen]);

//     // const menuToggleHandler = () => {
//     //     setMenuOpen((p) =>!p);
//     // };


//     return (
//         <nav className="navbar navbar-expand-lg navbar-light bg-light">
//             <div className="container container-fluid">
//                 <Link to="/" className="navbar-brand" >
//                     <img src={LOGO_01} className="img-fluid" style={{ width: '90px', height: '50px' }} alt="LOGO_01" border="0" />
//                 </Link>
//                 <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
//                     <span className="navbar-toggler-icon"></span>
//                 </button>
//                 <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
//                     {/* 1st one */}
//                     <span onClick={headerClickHandler}></span>
//                     {/* 2nd one or */}
//                     {/* <button onClick={headerClickHandler}></button> */}
//                     {/* 3rd one or */}
//                     {/* <a href="#" onClick={headerClickHandler}></a> */}

//                     <ul className="navbar-nav navbar_nav_ul">
//                         <li className="nav_item px-2">
//                             {/* <Link to='home' className="nav-link active" aria-current="page" onClick={menuToggleHandler}>Home</Link> */}
//                             <Link to='/' className="nav-link active nav_link" aria-current="page">Home</Link>
//                         </li>
//                         <li className="nav_item px-2">
//                             {/* <Link to='about' className="nav-link" onClick={menuToggleHandler}>About</Link> */}
//                             <Link to='about-us' className="nav-link nav_link">About Us</Link>
//                         </li>
//                         {/* <li className="nav_item px-2">
//                             <Link to='admission' className="nav-link" >Admission</Link>
//                         </li> */}
//                         {/* hdkfjdkf start*/}
//                         <li className="nav_item px-2">
//                             <Link to='#' className="nav-link nav_link">Admission</Link>
//                             <ul class="navbar_nav_ul dropdown_admission">
//                                 <li class="nav_item">
//                                     <Link to="online-admission-process" className="nav-link nav_link">Online Application Process</Link>
//                                 </li>
//                                 <li class="nav_item">
//                                     <Link to="admission-form" className="nav-link nav_link">Application Form</Link>
//                                 </li>
//                             </ul>
//                         </li>
//                         <li className="nav_item px-2">
//                             <Link to="#"  className="nav-link nav_link">Academics</Link>
//                             <ul class="navbar_nav_ul dropdown_academics">
//                                 <li class="nav_item">
//                                     <Link to="academic-program" className="nav-link nav_link">Academic Program</Link>
//                                 </li>
//                                 <li class="nav_item">
//                                     <Link to="curriculum-overview" className="nav-link nav_link">Curriculum Overview</Link>
//                                 </li>
//                                 <li class="nav_item">
//                                     <Link to="outdoor-classroom" className="nav-link nav_link">The Outdoor Classroom</Link>
//                                 </li>
//                                 <li class="nav_item">
//                                     <Link to="extra-carricular-activities" className="nav-link nav_link">Extra Carricular Activities</Link>
//                                 </li>
//                                 <li class="nav_item">
//                                     <Link to="syllabus" className="nav-link nav_link">Syllabus</Link>
//                                 </li>
//                             </ul>
//                         </li>
//                         {/* <li className="nav_item px-2">
//                             <Link to="#"  className="nav-link nav_link">Academics</Link>
//                             <ul class="navbar_nav_ul dropdown_p">
//                                 <li class="nav_item">
//                                     <Link to="academics" className="nav-link nav_link">Academic Program</Link>
//                                 </li>
//                                 <li class="nav_item">
//                                     <Link to="#" className="nav-link nav_link">Curriculum Overview</Link>
//                                 </li>
//                                 <li class="nav_item">
//                                     <Link to="#" className="nav-link nav_link">The Outdoor Classroom</Link>
//                                 </li>
//                                 <li class="nav_item">
//                                     <Link to="#" className="nav-link nav_link">Extra Carricular Activities</Link>
//                                 </li>
//                                 <li class="nav_item">
//                                     <Link to="#" className="nav-link nav_link">Syllabus</Link>
//                                 </li>
//                             </ul>
//                         </li> */}
//                          {/* hdkfjdkf end*/}
                       
//                         <li className="nav_item px-2">
//                             <Link to='notice' className="nav-link nav_link" >Notice</Link>
//                         </li>
//                         {/* <li className="nav_item px-2">
//                             <Link to='info-center' className="nav-link nav_link" >Info Center</Link>
//                         </li> */}
//                         <li className="nav_item px-2">
//                             <Link to='#' className="nav-link nav_link">Info Center</Link>
//                             <ul class="navbar_nav_ul dropdown_info_center">
//                                 <li class="nav_item">
//                                     <Link to="news-event" className="nav-link nav_link">News & Event</Link>
//                                 </li>
//                                 <li class="nav_item">
//                                     <Link to="gallery" className="nav-link nav_link">Gallery</Link>
//                                 </li>
//                                 <li class="nav_item">
//                                     <Link to="alumni" className="nav-link nav_link">Alumni</Link>
//                                 </li>
//                                 <li class="nav_item">
//                                     <Link to="school-calendar" className="nav-link nav_link">School Calendar</Link>
//                                 </li>
//                             </ul>
//                         </li>
//                         <li className="nav_item px-2">
//                             <Link to='contact-us' className="nav-link nav_link" >Contact us</Link>
//                         </li>
//                         <li className=" px-2">
//                             <Link to='pay-online' className="nav-link nav_link btn_learn_more" >Pay Online</Link>
//                         </li>
//                     </ul>

//                 </div>
//             </div>
//         </nav>
//     );
// };

// export default Header;


///// bgackup file
// // import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from "react-router-dom";
// import logo from '../../Images/logo.png';

// const Header = () => {
//     // const [menuOpen, setMenuOpen] = useState(false);
//     // const [size, setSize] =useState({
//     //     width: undefined,
//     //     hight: undefined,
//     // });
//     const navigate = useNavigate();
//     const headerClickHandler = e => {
//         e.preventDefault()
//         // navigate(-1);
//         navigate("/")
//     };

//     // useEffect(() => {
//     //     const handleResize = () => {
//     //         setSize()({
//     //             width: window.innerWidth,
//     //             height: window.innerHeight,
//     //         });
//     //     };
//     //     window.addEventListener("resize", handleResize);
//     // }, []);

//     // useEffect(() => {
//     //     if(size.width > 768 && menuOpen){
//     //         setMenuOpen(false);
//     //     }
//     // }, [size.width, menuOpen]);

//     // const menuToggleHandler = () => {
//     //     setMenuOpen((p) =>!p);
//     // };


//     return (
//         <nav className="navbar navbar-expand-lg navbar-light bg-light">
//             <div className="container container-fluid">
//                 <Link to="/" className="navbar-brand" >
//                     <img src={logo} className="img-fluid" style={{ width: '70px', height: '50px' }} alt="logo" border="0" />
//                 </Link>
//                 <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
//                     <span className="navbar-toggler-icon"></span>
//                 </button>
//                 <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
//                     {/* 1st one */}
//                     <span onClick={headerClickHandler}></span>
//                     {/* 2nd one or */}
//                     {/* <button onClick={headerClickHandler}></button> */}
//                     {/* 3rd one or */}
//                     {/* <a href="#" onClick={headerClickHandler}></a> */}

//                     <ul className="navbar-nav">
//                         <li className="nav-item px-2">
//                             {/* <Link to='home' className="nav-link active" aria-current="page" onClick={menuToggleHandler}>Home</Link> */}
//                             <Link to='/' className="nav-link active" aria-current="page">Home</Link>
//                         </li>
//                         <li className="nav-item px-2">
//                             {/* <Link to='about' className="nav-link" onClick={menuToggleHandler}>About</Link> */}
//                             <Link to='about-us' className="nav-link">About Us</Link>
//                         </li>
//                         <li className="nav-item px-2">
//                             <Link to='admission' className="nav-link" >Admission</Link>
//                         </li>
//                         <li className="nav-item px-2">
//                             <Link to='academics' className="nav-link" >Academics</Link>
//                         </li>
//                         <li className="nav-item px-2">
//                             <Link to='notice' className="nav-link" >Notice</Link>
//                         </li>
//                         <li className="nav-item px-2">
//                             <Link to='info-center' className="nav-link" >Info Center</Link>
//                         </li>
//                         <li className="nav-item px-2">
//                             <Link to='contact-us' className="nav-link" >Contact us</Link>
//                         </li>
//                         <li className="nav-item px-2">
//                             <Link to='pay-online' className="nav-link btn_learn_more" >Pay Online</Link>
//                         </li>
//                     </ul>

//                 </div>
//             </div>
//         </nav>
//     );
// };

// export default Header;