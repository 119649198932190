import React from 'react';
import SchoolCalendarBanner from './SchoolCalendarBanner';
import CalendarSchedule from '../../Images/CalendarSchedule.png';


const SchoolCalendar = () => {
    return (
        <>
            <SchoolCalendarBanner />
            <div className="container school_calendar">
                <div className="row g-5">
                    <div className="col-md-6 ">
                        <div className="schedule_playgroup ">
                            <div className="text-center">
                                <a href="#">
                                    <img src={CalendarSchedule} className="img-fluid" style={{ width: "60px", height: "50px" }} alt='CalendarSchedule' />
                                </a>
                            </div>
                            <p className="playgroup_text">
                                Term 1 & Term 2 (Playgroup - Class 1)
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="schedule_class_2">
                            <div className="text-center">
                                <a href="#">
                                <img src={CalendarSchedule} className="img-fluid" style={{ width: "60px", height: "50px" }} alt='CalendarSchedule' />
                                </a>
                            </div>
                            <p className="class_2_text">
                                Term 1 & Term 2 (Class 2 - Class 8)
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default SchoolCalendar;