import React from 'react';
import SchoolCalBanner from '../../Images/SchoolCalBanner.png';


const SchoolCalendarBanner = () => {
    return (
        <>
            <div class="banner_school_calendar">
                <img src={SchoolCalBanner} className="img-fluid" alt="SchoolCalBanner" />
                <div class="content_img">
                    <div class="container d-flex justify-content-start">
                        <h1 class="text-white">School Calendar</h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SchoolCalendarBanner;