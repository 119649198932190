import React from 'react';
import image_50 from '../../Images/image_50.png';
import image_49 from '../../Images/image_49.png';
import image_52 from '../../Images/image_52.png';
import AcademicProgramBanner from './AcademicProgramBanner';

const AcademicProgram = () => {
    return (
        <>
            <AcademicProgramBanner />
            <div className="container mb-5 mt-5">
                {/* Early Years Section */}
                <div className="row g-3 pt-5 mb-5">
                    <div className="col-md-6">
                        <img src={image_50} className=" img-fluid" style={{ width: '100%', height: '280px' }} alt="Early Years" />
                    </div>
                    <div className="col-md-6">
                        <div className="ps-md-5">
                            <h6 className="header_early_years">EARLY YEARS (EY)</h6>
                            <h4 className="header_form_play">Play Groups to Kindergarten 2 (PG to KG2)</h4>
                            <p className="text-muted early_years_description">
                                Our Early Years program welcomes children aged 4 to 6, guiding them through exciting activities that build strong educational foundations. At Riverdale, we combine playful learning with spiritual growth, preparing students for success in their academic journey.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Primary Years Section */}
                <div className="row g-3 pt-2 mb-5">
                    <div className="col-md-6">
                        <h6 className="header_primary_years">PRIMARY YEARS (PY)</h6>
                        <h4 className="header_from_grade">Grade 1 to Grade 4</h4>
                        <p className="text-muted primary_years_description pe-md-5">
                            In our Primary Years program, students from Grades 1 to 4 continue to grow academically. We nurture each child's curiosity, developing creativity and analytical skills that help foster a love of learning and pave the way for future academic success.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={image_49} className=" img-fluid" style={{ width: '100%', height: '280px' }} alt="Primary Years" />
                    </div>
                </div>

                {/* Middle Years Section */}
                <div className="row g-3 pt-5 mb-5">
                    <div className="col-md-6">
                        <img src={image_52} className=" img-fluid" style={{ width: '100%', height: '280px' }} alt="Middle Years" />
                    </div>
                    <div className="col-md-6">
                        <div className="ps-md-5">
                            <h6 className="header_middle_years">MIDDLE YEARS (MY)</h6>
                            <h4 className="header_from_grade">Grade 5 to Grade 8</h4>
                            <p className="text-muted middle_years_description">
                                Our Middle Years program bridges the gap between Primary and Higher Secondary education. Spanning Grades 5 to 8, this program equips students with the essential skills and knowledge needed for higher education, ensuring they are ready to excel.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AcademicProgram;
