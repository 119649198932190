import React from 'react';
import Skype from '../../Images/Skype.png';
import Email from '../../Images/Email.png';
import WhatsUpCircle from '../../Images/WhatsUpCircle.png';
import ContactPhone from '../../Images/ContactPhone.png';
import Location from '../../Images/Location.png';
import ContactUsBanner from './ContactUsBanner';

const ContactUs = () => {
    return (
        <>
            <ContactUsBanner />
            <div className="container mt-5">
                <div className="row g-3 mt-5">
                    <div className="col-md-6">
                        <h3 className="text-muted header_contact_us">Contact Us</h3>
                        <div class="row g-3">
                            <div class="col-6">
                                <span class="d-flex justify-content-start">
                                    <img src={Location} class="img-fluid rounded-start" style={{ width: '25px', height: '25px' }} alt="Location" border="0" />
                                    <p class="px-3 text-muted contact_us_description">3 Dhakeswari Road, Lalbagh Dhaka</p>
                                </span>
                            </div>
                            <div class="col-6">
                                <span class="d-flex justify-content-start">
                                    <img src={Skype} class="img-fluid rounded-start" style={{ width: '25px', height: '25px' }} alt="Skype" border="0" />
                                    <p class="px-3 text-muted contact_us_description">riverdaleschool</p>
                                </span>
                            </div>
                            <div class="col-6">
                                <span class="d-flex justify-content-start">
                                    <img src={Email} class="img-fluid rounded-start" style={{ width: '25px', height: '25px' }} alt="Email" border="0" />
                                    <p class="px-3 text-muted contact_us_description">riverdale@gmail.com</p>
                                </span>
                            </div>
                            <div class="col-6">
                                <span class="d-flex justify-content-start">
                                    <img src={WhatsUpCircle} class="img-fluid rounded-start" style={{ width: '25px', height: '25px' }} alt="WhatsUpCircle" border="0" />
                                    <p class="px-3 text-muted contact_us_description">+8801552-356559</p>
                                </span>
                            </div>
                            <div class="col-6">
                                <span class="d-flex justify-content-start">
                                    <img src={ContactPhone} class="img-fluid rounded-start" style={{ width: '25px', height: '25px' }} alt="ContactPhone" border="0" />
                                    <p class="px-3 text-muted contact_us_description">+8801552-356559</p>
                                </span>
                            </div>
                            <div class="col-12">
                                <div class="d-flex justify-content-center mt-4">
                                    <a href="#" class="fs-4 px-3 "><i class="contact_us_social_icon fab fa-facebook-f"></i></a>
                                    <a href="#" class="fs-4 px-3 "><i class="contact_us_social_icon fab fa-twitter"></i></a>
                                    <a href="#" class="fs-4 px-3 "><i class="contact_us_social_icon fab fa-instagram"></i></a>
                                    <a href="#" class="fs-4 px-3 "><i class="contact_us_social_icon fab fa-linkedin-in"></i></a>
                                    <a href="#" class="fs-4 px-3 "><i class="contact_us_social_icon fab fa-pinterest-p"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="text-muted header_get_touch">Get in Touch</h3>
                        <form class="row g-3">
                            <div class="col-md-12">
                                <input type="text" class="form-control" placeholder="First Name" aria-label="Full Name" />
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control" placeholder="Institute Name" aria-label="Institute name" />
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control" placeholder="Email Address" aria-label="Email address" />
                            </div>
                            <div class="mb-3">
                                <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Write your Message" rows="5"></textarea>
                            </div>
                            <div class="col-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="gridCheck" />
                                    <label class="form-check-label" for="gridCheck">
                                        <span className="text-muted">I agree to the</span>
                                        <span className="text_color"> Privacy Policy</span>
                                        <span className="text-muted"> and</span>
                                        <span className="text_color"> Terms of Service.</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="submit" class="btn btn_free_trail_now">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.726859069427!2d90.38676011445527!3d23.721446195799885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8dbf9b2d775%3A0xd67e2c3e148d6671!2sRiverdale%20International%20School!5e0!3m2!1sen!2sbd!4v1643085053794!5m2!1sen!2sbd" style={{ width: "100%", height: "400px", frameborder: "0" }} allowfullscreen="" loading="lazy"></iframe>
            </div>
        </>

    );
};

export default ContactUs;