import React from 'react';
import CurriculumOverviewBanner from './CurriculumOverviewBanner';

const CurriculumOverview = () => {
    return (
        <>
            <CurriculumOverviewBanner />
            <div className="container mb-4 mt-5">
                {/* Curriculum Overview Section */}
                <div className="mb-3">
                    <h4 className="header_curriculum_overview">CURRICULUM OVERVIEW</h4>
                    <p className="text-muted curriculum_rds_description">
                        At Riverdale School (RDS), we provide a nurturing environment designed to foster the growth of every child across all dimensions of life. Our curriculum is built to develop socially responsible global citizens, while recognizing that each child has unique strengths, interests, and learning approaches.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted curriculum_rds_description">
                        Our curriculum is based on the GCE syllabi, with English as the primary medium of instruction, ensuring that students receive a comprehensive and internationally recognized education.
                    </p>
                </div>

                {/* EYFS Section */}
                <div className="mb-3">
                    <h4 className="header_curriculum_early_years">EYFS (Early Years Foundation Stage) for PG to KG2</h4>
                    <p className="text-muted early_years_description">
                        In the Early Years program, we prioritize holistic child development. Through EYFS, we provide a stimulating environment where children are encouraged to express themselves, develop independence, and build a foundation for lifelong learning.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted early_years_description">
                        EYFS focuses on critical early learning areas, helping children develop essential skills for structured learning in later years. Our approach supports both educational achievement and personal growth, nurturing thoughtful, well-rounded individuals.
                    </p>
                </div>

                {/* Areas of Learning */}
                <div className="mb-3">
                    <h2 className="header_curriculum_areas">Key Areas of Learning</h2>
                    <ul className="curriculum_list">
                        <li className="text-muted">Language Development</li>
                        <li className="text-muted">Cognitive Growth</li>
                        <li className="text-muted">Physical and Emotional Development</li>
                        <li className="text-muted">Social Interaction</li>
                        <li className="text-muted">Moral and Spiritual Values</li>
                    </ul>
                </div>

                {/* PLSC Section */}
                <div className="mb-3">
                    <h4 className="header_curriculum_middle_years">PLSC for Grades 1 to 8</h4>
                    <p className="text-muted middle_years_description">
                        The Primary and Middle Years curriculum at RDS is grounded in the PLSC framework, which prepares students for higher-level education, especially IGCSE. Through carefully structured content and teaching methods, we help students achieve both academic excellence and personal growth, setting them on a path to success.
                    </p>
                </div>
                <div className="mb-3">
                    <p className="text-muted middle_years_description">
                        The curriculum covers a wide range of subjects, offering students the opportunity to explore different disciplines and develop diverse skill sets. Our key focus areas include:
                    </p>
                </div>

                {/* Curriculum Subjects Table */}
                <div className="table-responsive">
                    <table className="table table-bordered text-muted text-center table-hover">
                        <thead>
                            <tr>
                                <th className="w-50">Core Subjects</th>
                                <th className="w-50">Additional Subjects</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>English Language</td>
                                <td>History & Geography (Individuals & Societies)</td>
                            </tr>
                            <tr>
                                <td>Bengali</td>
                                <td>Science</td>
                            </tr>
                            <tr>
                                <td>Arabic (Second Language)</td>
                                <td>Arts</td>
                            </tr>
                            <tr>
                                <td>Mathematics</td>
                                <td>Moral Education</td>
                            </tr>
                            <tr>
                                <td>ICT (Computer Studies)</td>
                                <td>Physical Education (PE)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default CurriculumOverview;
