import React from 'react';
import Notice_Banner from '../../Images/Notice_Banner.png';


const NoticeBanner = () => {
    return (
        <>
            <div class="banner_school_calendar">
                <img src={Notice_Banner} className="img-fluid" alt="Notice_Banner" />
                <div class="content_img">
                    <div class="container d-flex justify-content-start">
                        <h1 class="text-white">Notice Board</h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NoticeBanner;