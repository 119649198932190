import React from 'react';
import Curriculum_Overview_Banner from '../../Images/Curriculum_Overview_Banner.png';

const CurriculumOverviewBanner = () => {
    return (
        <>
            <div class="banner_about_us">
                <img src={Curriculum_Overview_Banner} className="img-fluid" alt="Curriculum_Overview_Banner" />
                <div class="content_img">
                    <div class="container d-flex justify-content-start">
                        <h1 class="text-white">Curriculum Overview</h1>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CurriculumOverviewBanner;