import React from 'react';
import Gallery from '../main_home_page/Gallery';
import OutdoorClassroomBanner from './OutdoorClassroomBanner';

const OutdoorClassroom = () => {
    return (
        <>
            <OutdoorClassroomBanner />
            <div className="container mb-5 mt-5">
                <div className="row g-3">
                    <div className="col-md-12">
                        <div className="mt-0">
                            <h4 className="header_real_world_learning">REAL WORLD LEARNING</h4>
                            <p className="text-muted nature_description">
                                Nature serves as a boundless source of curiosity and inspiration for children. Engaging with the natural world fosters both physical and mental development, helping children build a deeper sense of spirituality. By interacting with their environment, students learn through hands-on experiences, becoming innovative, imaginative, and adept problem-solvers.
                            </p>
                            <p className="text-muted lgs_description">
                                Our Learning Garden School (LGS) extends these benefits with activities that break the traditional classroom walls. Through theme-based tours and trips, students gain valuable real-world experiences and insights into their surroundings.
                            </p>
                            <p className="text-muted field_trips_description">Field trips include visits to:</p>
                            <ul className='field_trips_ul'>
                                <li className="text-muted field_trips_list">Library</li>
                                <li className="text-muted field_trips_list">Art Museum</li>
                                <li className="text-muted field_trips_list">Historical Monuments</li>
                                <li className="text-muted field_trips_list">Botanical Garden</li>
                                <li className="text-muted field_trips_list">Theatre</li>
                                <li className="text-muted field_trips_list">Zoo</li>
                                <li className="text-muted field_trips_list">Farm</li>
                            </ul>
                            <p className="text-muted trip_done">Additionally, students enjoy weekly trips to the nearest park for a closer connection with nature.</p>
                        </div>
                    </div>
                </div>
                <div className="row g-3 mt-5">
                    <Gallery />
                </div>
            </div>
        </>
    );
};

export default OutdoorClassroom;
